import { Injectable } from '@angular/core';
import { ActivityTypeModelInterface } from '@interfaces/activity-types/activity-type.model.interface';
import { ActivityTypeQueryParamsInterface } from '@interfaces/activity-types/activity-type.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ActivityTypesService extends AbstractService<
  ActivityTypeModelInterface,
  ActivityTypeQueryParamsInterface
> {
  protected errorsSection = 'errors.activities-types';
  protected endPoint = '/activities-types/';
}
