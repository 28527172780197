import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const imageExtension = ['jpg', 'png', 'svg', 'bmp', 'jpeg', 'webp'];
const documentExtension = ['doc', 'doc', 'xls', 'pdf', 'csv'];

@Pipe({
  name: 'getFileThumbnail',
})
export class GetFileThumbnail implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): string {
    const cleanUrl = url.replace(/\"/g, '');

    const sanitizedUrl = this.sanitizer.sanitize(
      SecurityContext.URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(cleanUrl),
    );

    const extension = sanitizedUrl.split('.').at(-1).toLowerCase();

    const isImage = imageExtension.includes(extension);

    if (isImage) {
      return `<img class="file-cdn-modal__image" src="${sanitizedUrl}">`;
    }

    const isDocument = documentExtension.find((ext) => ext.includes(extension));

    if (isDocument) {
      return `<i class="fa-light fa-file-${isDocument} file-cdn-modal__icon">`;
    }

    return `<i class="fa-regular fa-file file-cdn-modal__icon">`;
  }
}
