import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import {
  ActivityService,
  ContentsService,
  EducationalDetailsService,
  FilesService,
  ItemsService,
  LaboratoriesService,
  SessionSingleService,
  SessionsService,
  UnitSingleService,
  UnitsService,
  AgreementsService,
  MediasService,
} from './load-content.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class LoadContentModule {
  static forRoot(): ModuleWithProviders<LoadContentModule> {
    return {
      ngModule: LoadContentModule,
      providers: [
        LaboratoriesService,
        EducationalDetailsService,
        UnitsService,
        UnitSingleService,
        SessionsService,
        SessionSingleService,
        ContentsService,
        ActivityService,
        ItemsService,
        FilesService,
        AgreementsService,
        MediasService,
      ],
    };
  }
}
