import { Injectable } from '@angular/core';
import { Teachers } from '@interfaces/teachers/teachers.model.interface';
import { TeachersQueryParamsInterface } from '@interfaces/teachers/teachers.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class TeachersService extends AbstractService<
  Teachers,
  TeachersQueryParamsInterface
> {
  protected errorsSection = 'errors.teachers';
  protected endPoint = '/teachers/';
  public createFormation(
    object: Teachers,
    section: string | string[] = 'standard',
  ) {
    this.create(object, section, [], `${this.endPoint}formation`);
  }
}
