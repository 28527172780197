<div class="background background--color-gray">
  <div class="d-flex flex-column align-items-center container">
    <div class="row personList col-12">
      <div class="row py-3 px-0 justify-content-between">
        <h1 class="title-text title-text--primary px-0">
          MIS ALUMNOS - Carga masiva
        </h1>
      </div>
    </div>
    <div class="row personList col-12">
      <div
        class="card-container--border card-container card-container--person row p-5 align-items-center justify-content-between"
      >
        <div
          class="px-5 pt-3 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="col-12 text-center">
            <h2 class="heading2">AÑADE A TODOS TUS ALUMNOS</h2>
          </div>
          <div class="col-12 my-5">
            <h3 class="body5">
              Si quieres subir la lista completa de tus alumnos de este año,
              pincha en el botón y añade un excel que contenga sus nombres,
              fechas de nacimiento y correos electrónicos si los tienen.
            </h3>
          </div>
          <div class="col-12 text-center pt-4">
            <h4 class="body5">
              Descarga la plantilla de ejemplo
              <a
                href="/assets/xls/alumnos_excel.xlsx" download="alumnos_demo.xlsx"
                class="underlineText cursor-pointer"
                >aquí.</a
              >
            </h4>
          </div>
          <div class="mt-5 mb-3 position-relative w-75">
            <app-upload-files
              [config]="{
                max: 1,
                type: 'files',
                placeHolder: 'Adjuntar Archivo',
                fullWidth: true
              }"
              [info]="true"
              [xls]="true"
              [showRepository]="false"
              [autoUpload]="false"
              #importFile
            ></app-upload-files>
          </div>
          <div class="col-lg-12 col-xl-9 d-flex justify-content-start">
            <div class="d-flex justify-content-start px-4">
              <i
                class="lab lab__index lab__index-img icon-info text-color--primary body1"
              ></i>
              <p class="pt-3 px-4 mx-2">
                Límite de alumnos para esta línea:
                {{ program?.studentsNumber }}.
              </p>
            </div>
          </div>
          <ng-container *ngIf="importResult">
            <div class="col-lg-12 col-xl-9 d-flex justify-content-start">
              <div class="d-flex justify-content-start px-4">
                <i
                  class="lab lab__index lab__index-img icon-info text-color--primary body1"
                ></i>
                <p class="pt-3 px-4 mx-2">
                  Se han intentado subir: <b>{{ importResult.total }}</b>
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-xl-9 d-flex justify-content-start">
              <div class="d-flex justify-content-start px-4">
                <i
                  class="lab lab__index lab__index-img icon-info text-color--primary body1"
                ></i>
                <p class="pt-3 px-4 mx-2">
                  Se han subido: <b>{{ importResult.uploaded }}</b>
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-xl-9 ustify-content-start">
              <div *ngFor="let error of importResult.errors">
                
                <div class="d-flex justify-content-start px-4">
                  <i
                    class="lab lab__index lab__index-img icon-users text-color--primary body1"
                  ></i>
                  <p class="pt-3 px-4 mx-2">
                    <span><b>{{ error.value }}</b>: {{ error.message }}</span>
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="py-3 col-12 d-flex justify-content-end mt-4">
            <button
              class="button--primary button mx-5"
              (click)="navigateReturn()"
            >
              Cancelar
            </button>
            <button
              class="button--primary button"
              [ngClass]="{
                'button--primary' : !isImporting || importFile?.gallery?.length, 
                'button--secondary': isImporting || !importFile?.gallery?.length
                }"
              [disabled]="isImporting || !importFile?.gallery?.length"
              (click)="import()"
            >
              Subir Archivo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
