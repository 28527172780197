import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { StripePaymentInterface } from '@interfaces/stripe/stripe-payment.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class StripePaymentService extends AbstractService<
  StripePaymentInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.stripe';
  protected endPoint = '/payments/stripe/';
}
