import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getExtension',
})
export class GetExtensionPipe implements PipeTransform {
  transform(name: string, withDot: boolean = true): string {
    const dot = (withDot && '.') || '';
    return `${dot}${name.split('.').at(-1)}`;
  }
}
