import { Injectable } from '@angular/core';
import { ActivityCategoryProcessModelInterface } from '@interfaces/activity-categories-process/activity-category-process.model.interface';
import { ActivityCategoryProcessQueryParamsInterface } from '@interfaces/activity-categories-process/activity-category-process.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ProcessesService extends AbstractService<
  ActivityCategoryProcessModelInterface,
  ActivityCategoryProcessQueryParamsInterface
> {
  protected errorsSection = 'errors.processes';
  protected endPoint = '/activities-categories-processes/';
}
