import { Injectable } from '@angular/core';
import { Activity } from '@interfaces/activity/activity.model.interface';
import { ActivityQueryParamsInterface } from '@interfaces/activity/activity.queryParams.interface';
import { Agreements } from '@interfaces/agreements/agreements.model.interface';
import { AgreementsQueryParamsInterface } from '@interfaces/agreements/agreements.queryParams.interface';
import { Contents } from '@interfaces/contents/contents.model.interface';
import { ContentsQueryParamsInterface } from '@interfaces/contents/contents.queryParams.interface';
import { EducationalDetails } from '@interfaces/educational-details/educational-details.model.interface';
import { EducationalDetailsQueryParamsInterface } from '@interfaces/educational-details/educational-details.queryParams.interface';
import { FileInterface } from '@interfaces/file/file.model.interface';
import { FileQueryParamsInterface } from '@interfaces/file/file.queryParams.interface';
import { Items } from '@interfaces/items/items.model.interface';
import { ItemsQueryParamsInterface } from '@interfaces/items/items.queryParams.interface';
import { Laboratories } from '@interfaces/laboratories/laboratories.model.interface';
import { LaboratoriesQueryParamsInterface } from '@interfaces/laboratories/laboratories.queryParams.interface';
import { MediaQueryParamsInterface } from '@interfaces/media/media.queryParams.interface';
import { Sessions } from '@interfaces/sessions/sessions.model.interface';
import { SessionsQueryParamsInterface } from '@interfaces/sessions/sessions.queryParams.interface';
import { TeacherLaboratories } from '@interfaces/teacher-laboratories/teacher-laboratories.model.interface';
import { TeacherLaboratoriesQueryParamsInterface } from '@interfaces/teacher-laboratories/teacher-laboratories.queryParams.interface';
import { Units } from '@interfaces/units/units.model.interface';
import { UnitsQueryParamsInterface } from '@interfaces/units/units.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class LaboratoriesService extends AbstractService<
  Laboratories,
  LaboratoriesQueryParamsInterface
> {
  protected errorsSection = 'errors.laboratories';
  protected endPoint = '/laboratories/';

  public getSchoolLaboratories(
    queryParams: LaboratoriesQueryParamsInterface,
    section: string,
  ) {
    const endPoint = `${this.endPoint}admin`;

    this.getAll(queryParams, section, [], endPoint);
  }
}
@Injectable()
export class TeacherLaboratoriesService extends AbstractService<
  TeacherLaboratories,
  TeacherLaboratoriesQueryParamsInterface
> {
  protected errorsSection = 'errors.teacherLaboratories';
  protected endPoint = '/laboratories/teacher';
}
@Injectable()
export class UnitsService extends AbstractService<
  Units,
  UnitsQueryParamsInterface
> {
  protected errorsSection = 'errors.laboratories';
  protected endPoint = '/laboratories/%s/units';
}

@Injectable()
export class UnitSingleService extends AbstractService<
  Units,
  UnitsQueryParamsInterface
> {
  protected errorsSection = 'errors.units';
  protected endPoint = '/units/';
}

@Injectable()
export class SessionsService extends AbstractService<
  Sessions,
  SessionsQueryParamsInterface
> {
  protected errorsSection = 'errors.units';
  protected endPoint = '/units/%s/sessions';
}

@Injectable()
export class SessionSingleService extends AbstractService<
  Sessions,
  SessionsQueryParamsInterface
> {
  protected errorsSection = 'errors.units';
  protected endPoint = '/sessions/';
}

@Injectable()
export class ContentsService extends AbstractService<
  Contents,
  ContentsQueryParamsInterface
> {
  protected errorsSection = 'errors.contents';
  protected endPoint = '/contents/';
}

@Injectable()
export class ActivityService extends AbstractService<
  Activity,
  ActivityQueryParamsInterface
> {
  protected errorsSection = 'errors.activities';
  protected endPoint = '/activities/';
}

@Injectable()
export class ItemsService extends AbstractService<
  Items,
  ItemsQueryParamsInterface
> {
  protected errorsSection = 'errors.items';
  protected endPoint = '/items/';
}

@Injectable()
export class EducationalDetailsService extends AbstractService<
  EducationalDetails,
  EducationalDetailsQueryParamsInterface
> {
  protected errorsSection = 'errors.educationalDetails';
  protected endPoint = '/educationalDetails/';
}

@Injectable()
export class FilesService extends AbstractService<
  FileInterface,
  FileQueryParamsInterface
> {
  protected errorsSection = 'errors.files';
  protected endPoint = '/files/';
}

@Injectable()
export class MediasService extends AbstractService<
  FileInterface,
  MediaQueryParamsInterface
> {
  protected errorsSection = 'errors.medias';
  protected endPoint = '/medias/';
}

@Injectable()
export class AgreementsService extends AbstractService<
  Agreements,
  AgreementsQueryParamsInterface
> {
  protected errorsSection = 'errors.agreements';
  protected endPoint = '/agreements/';
}
