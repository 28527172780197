import { Injectable } from '@angular/core';
import { ActivityCategorizedModelInterface } from '@interfaces/activity-categorized/activity-categorized.model.interface';
import { ActivityCategorizedQueryParamsInterface } from '@interfaces/activity-categorized/activity-categorized.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ActivitiesCategorizedService extends AbstractService<
  ActivityCategorizedModelInterface,
  ActivityCategorizedQueryParamsInterface
> {
  protected errorsSection = 'errors.activities-categorized';
  protected endPoint = '/activities-categorized/';
}
