import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserInterface } from '@interfaces/user/user.interface';
import { AbstractAuthService } from '@services/abstract/abstract.auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export abstract class AbstractAuthGuard
  implements CanActivate, CanActivateChild
{
  constructor(
    protected authService: AbstractAuthService,
    protected router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.getObservable().pipe(
      map((user: UserInterface) => {
        return this.check(user);
      }),
      catchError((err) => {
        return of(this.onError(err));
      }),
    );
  }

  protected abstract check(user): boolean;

  protected abstract onError(e): boolean;
}
