import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { FileInventoryService } from './file-inventory.service';
// services

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class FileInventoryModule {
  static forRoot(): ModuleWithProviders<FileInventoryModule> {
    return {
      ngModule: FileInventoryModule,
      providers: [FileInventoryService],
    };
  }
}
