import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard extends AbstractAuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  protected check(user): boolean {
    if (!user) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  protected onError(e): boolean {
    return false;
  }
}

@Injectable()
export class AuthGuardV2 extends AbstractAuthGuard {
  constructor(protected authService: AuthService, protected router: Router) {
    super(authService, router);
  }

  protected check(user): boolean {
    if (!user) {
      this.router.navigate(['/v2']);
      return false;
    }

    return true;
  }

  protected onError(e): boolean {
    return false;
  }
}
