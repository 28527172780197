import { NgModule } from '@angular/core';
import { BaseLayoutComponent } from './base-layout.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [CommonModule, PipesModule],
  exports: [BaseLayoutComponent],
})
export class BaseLayoutModule {}
