import {
  schoolInvitations,
  schoolInvitationsLinkTeachersBody,
} from './../../interfaces/school-invitation/school-invitation.model.interface';
import { Injectable } from '@angular/core';
import { Schools } from '@interfaces/schools/schools.model.interface';
import { SchoolsQueryParamsInterface } from '@interfaces/schools/schools.queryParams.interface';
import { Teachers } from '@interfaces/teachers/teachers.model.interface';
import { TeachersQueryParamsInterface } from '@interfaces/teachers/teachers.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { SchoolInvitationQueryParamsInterface } from '@interfaces/school-invitation/school-invitation.queryParams.interface';
import { UnlinkTeacher } from '@interfaces/school-invitation/unlink.teacher.model.interface';

@Injectable()
export class SchoolsService extends AbstractService<
  Schools,
  SchoolsQueryParamsInterface
> {
  protected errorsSection = 'errors.schools';
  protected endPoint = '/schools/';
}

@Injectable()
export class SchoolsTeachersService extends AbstractService<
  Teachers,
  SchoolsQueryParamsInterface
> {
  protected errorsSection = 'errors.schools';
  protected endPoint = '/schools/%s/teachers';
}

@Injectable()
export class SchoolsInvitationsService extends AbstractService<
  schoolInvitationsLinkTeachersBody,
  SchoolInvitationQueryParamsInterface
> {
  protected errorsSection = 'errors.teachers.invitations';
  protected endPoint = '/school-invitations/';

  public linkTeachers(
    data: schoolInvitationsLinkTeachersBody,
    section: string,
  ) {
    const endpoint = `${this.endPoint}assign-teachers`;

    this.create(data, section, [], endpoint);
  }

  public unlinkTeacher(data: UnlinkTeacher, section: string) {
    const endpoint = `${this.endPoint}unlink-teacher`;

    this.create(data, section, [], endpoint);
  }
}
