<div
  class="d-flex flex-column align-items-center background background--color-gray background--extend"
>
  <div class="row personList col-12">
    <div class="row py-3 px-0 justify-content-between">
      <h1 class="title-text title-text--primary px-0">
        MIS PROFESORES - Nuevo profesor
      </h1>
    </div>
  </div>
  <div class="row personList col-12">
    <div
      class="card-container--border card-container card-container--person row p-5 align-items-center justify-content-between"
    >
      <div
        class="px-5 pt-5 d-flex flex-column align-items-center justify-content-center"
      >
        <div class="col-12 text-center">
          <h2 class="heading2">AÑADE A TODOS TUS PROFESORES</h2>
        </div>
        <div class="col-12 my-5">
          <h3 class="body5">
            Si quieres subir la lista completa de tus profesores de este año,
            pincha en el botón y añade un excel que contenga sus nombres y
            correos electrónicos a los que se enviará la invitación.
          </h3>
        </div>
        <div class="col-12 text-center">
          <h4 class="body5">
            Descarga la plantilla de ejemplo
            <a
            href="/assets/csv/TEACHERS_INVITES_CSV.csv" download="invitacion_profe_demo.csv"
              class="underlineText cursor-pointer"
              >aquí</a
            >
          </h4>
        </div>
        <div class="mt-5 mb-1 position-relative w-75">
          <app-upload-files
            [config]="{
              max: 1,
              type: 'files',
              placeHolder: 'Adjuntar Archivo',
              fullWidth: true
            }"
            [info]="true"
            [csv]="true"
            [showRepository]="false"
            [autoUpload]="false"
            #importFile
          ></app-upload-files>
        </div>
        <div class="col-8 d-flex justify-content-start">
          <div class="d-flex justify-content-start">
            <img
              alt="Solo se puede subir 1 archivo"
              src="/assets/images/neuromindset/icons-svg/info.svg"
            />
            <p class="pt-3 px-3">
              A estas cuentas enviaremos las invitaciones para que los docentes
              activen sus cuentas. Asegúrate de poner aquí las direcciones de
              correo actual.
            </p>
          </div>
        </div>
        <div class="py-5 col-12 d-flex justify-content-end mt-4">
          <button
            class="button--primary button mx-5"
            (click)="onNavigateReturn()"
          >
            Cancelar
          </button>
          <button
            [ngClass]="{
              'button--primary': !isImporting || importFile?.gallery?.length,
              'button--secondary': isImporting || !importFile?.gallery?.length
            }"
            [disabled]="isImporting || !importFile?.gallery?.length"
            class="button"
            (click)="import()"
          >
            Subir archivo
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
