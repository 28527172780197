import { Injectable } from '@angular/core';
import { UserInterface } from '@interfaces/user/user.interface';
import { UserQueryParamsInterface } from '@interfaces/user/user.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class UsersService extends AbstractService<
  UserInterface,
  UserQueryParamsInterface
> {
  protected errorsSection = 'errors.user';
  protected endPoint = '/users/';
}
