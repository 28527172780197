import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { ThemesService } from './themes.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ThemesServiceModule {
  static forRoot(): ModuleWithProviders<ThemesServiceModule> {
    return {
      ngModule: ThemesServiceModule,
      providers: [ThemesService],
    };
  }
}
