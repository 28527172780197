import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import {
  createObservable,
  getPayload,
} from '@shared/utils/helpers/basic-observable.helper';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

export enum BACKGROUNDS {
  BLUE = '--bg-blue',
  YELLOW = '--bg-yellow',
  ORANGE = '--bg-orange',
  GREEN = '--bg-green',
}

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  bgColor: string;
  v2Filter: boolean = false;

  wavesSvg: string =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.326 261.803"><defs><clipPath id="a"><path fill="none" d="M5 0h1920.326v261.803H5z"/></clipPath><clipPath id="b"><path class="layout-waves__wave-color" d="M231.444 0a192.459 192.459 0 0 0-51.065 6.936C112.673 25.665 56.776 68.463 0 108.875V1001.8h1929.326V164.5c-47.616 9.23-100.634 42.207-144.442 34.721-90.344-15.4-179.607-98.824-263.083-136.226-95.154-42.613-172.479-15.7-268.253 25.569-95.746 41.268-197.46 89.325-300.074 69.9-96.726-18.3-178.4-94.853-276.871-95.738-24.685-.223-49.2 4.446-73.641 9.112-31.445 6.008-62.785 12.013-94.236 7.6C413.7 66.158 327.412.258 232.561 0Z" data-name="Trazado 7"/></clipPath></defs><g clip-path="url(#a)" data-name="Grupo de desplazamiento 1" style="mix-blend-mode:normal;isolation:isolate" transform="translate(-5)"><g data-name="Grupo 5" opacity=".08"><g clip-path="url(#b)" data-name="Grupo 4"><path class="layout-waves__wave-color" d="M-54.394 998.582 6.515-113.537 1983.252 4.2l-60.908 1112.12Z" data-name="Trazado 6"/></g></g></g></svg>';

  constructor(
    private renderer: Renderer2,
    private bOBService: BasicObservableService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    createObservable(this.bOBService, 'background', BACKGROUNDS.BLUE);
    this.handleRoute();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private handleRoute(): void {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).url),
        take(1),
      )
      .subscribe((url) => {
        this.v2Filter = url.includes('/v2');
        if (this.v2Filter) this.listenBGChange();
      });
  }

  private listenBGChange(): void {
    this.bOBService
      .getObservable('background')
      .pipe(takeUntil(this.onDestroy$), getPayload())
      .subscribe(this.bgChange.bind(this));
  }

  private bgChange(bgColor: BACKGROUNDS): void {
    this.bgColor = bgColor;
    this.renderer.setProperty(
      document.documentElement,
      'style',
      `--scrollbar-bg: var(${bgColor})`,
    );
  }
}
