import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilveInventoryInterface } from '@interfaces/file-inventory/file-inventory.interface';
import { FileInterface } from '@interfaces/file/file.model.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  debounceTime,
  lastValueFrom,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { FilesFacade } from './files.facade';
interface FileType {
  label: string;
  extension: string[];
  selected: ConstrainBoolean;
}

const fileTypes: FileType[] = [
  {
    label: 'Imagen',
    extension: ['.png', '.jpg', '.jpeg', '.bmp', '.svg', '.webp'],
    selected: false,
  },
  {
    label: 'Video',
    extension: ['.mp4', '.mpeg', '.avi'],
    selected: false,
  },
  {
    label: 'Documento',
    extension: ['.txt', '.doc', '.xls', '.csv', '.pdf'],
    selected: false,
  },
];

@Component({
  selector: 'app-files-cdn-modal',
  templateUrl: './files-cdn.component.html',
})
export class FilesCDNModalComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  searchSubject = new BehaviorSubject<string>(',');
  actualFilterSubject = new BehaviorSubject<string[]>([]);

  search$ = this.searchSubject.asObservable();
  actualFilter$ = this.actualFilterSubject.asObservable();

  files$ = this.filesFacade.files$();
  loading$ = this.filesFacade.isLoading$();
  headers$ = this.filesFacade.headers$().pipe(startWith({}));

  inventory$ = this.filesFacade.inventory$();
  inventoryLoading$ = this.filesFacade.isInventoryLoading$();
  inventoryHeaders$ = this.filesFacade.inventoryHeaders$().pipe(startWith({}));

  fileSelected: FilveInventoryInterface;
  files: FileInterface[];
  fileTypes = fileTypes;
  allowOnlyExtensions: string[] = [];

  private readonly searchDebouceMs = 200;
  private finalFile: FileInterface;

  searchMode$ = combineLatest([this.search$, this.actualFilter$]).pipe(
    map((flags) => flags.some((flag) => !!flag.length)),
  );

  /*
  dataSource$ = this.searchMode$.pipe(
    switchMap((searchMode) => (searchMode ? this.inventory$ : this.files$)),
  );
  */

  dataSource$ = this.inventory$;

  headersSource$ = this.searchMode$.pipe(
    startWith(true),
    switchMap((searchMode) =>
      searchMode ? this.inventoryHeaders$ : this.headers$,
    ),
  );

  loadingSource$ = combineLatest([this.loading$, this.inventoryLoading$]).pipe(
    map((flags) => flags.some((flag) => !!flag)),
  );

  constructor(public bsModalRef: BsModalRef, public filesFacade: FilesFacade) {}

  ngOnInit(): void {
    this.initSearch();
  }

  onSearch(filename: string): void {
    this.searchSubject.next(filename);
  }

  async goToPage(page: number): Promise<void> {
    const pageHandler = await lastValueFrom(
      combineLatest({
        headers: this.headersSource$,
        searchMode: this.searchMode$,
        filters: this.actualFilter$,
        searchTerm: this.search$,
      }).pipe(take(1)),
    );
    this.filesFacade.handlePage(pageHandler, page);
  }

  private initSearch(): void {
    this.searchSubject
      .pipe(takeUntil(this.destroyed$), debounceTime(this.searchDebouceMs))
      .subscribe(() => this.goToPage(1));
  }

  selectFilter(filterIdx: number): void {
    const filter = fileTypes[filterIdx];
    const alreadySelected = filter.selected;

    let selected: boolean;

    this.fileTypes.forEach((fileType) => {
      const match = filter.label === fileType.label;
      fileType.selected = alreadySelected ? false : match;
      selected = selected || fileType.selected;
    });

    this.actualFilterSubject.next(selected ? filter.extension : []);

    this.goToPage(1);
  }

  public viewFile(file: FilveInventoryInterface): void {
    const url = file.url.replace(/\"/g, '');
    window.open(url, '_blank');
  }

  selectFile(): void {
    const { id, url, name: description } = this.fileSelected;

    this.finalFile = {
      id,
      description,
      url,
    };

    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
