import { Injectable } from '@angular/core';
import { ActivityCategoryModelInterface } from '@interfaces/activity-categories/activity-category.model.interface';
import { ActivityCategoryQueryParamsInterface } from '@interfaces/activity-categories/activity-category.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class CategoriesService extends AbstractService<
  ActivityCategoryModelInterface,
  ActivityCategoryQueryParamsInterface
> {
  protected errorsSection = 'errors.categories';
  protected endPoint = '/activities-categories/';
}
