import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Token } from '@interfaces/token/token.interface';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  public auth$: Subscription;
  private tokens: Token;
  private clientID = environment.client.name;
  private clientSecret = environment.client.secret;
  private clientCredentials: string;

  constructor(private authService: AuthService) {
    this.clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);
    this.tokens = null as Token;
    this.auth$ = this.authService
      .getObservable('tokens')
      .pipe(filter((tokens) => !!tokens))
      .subscribe((value) => {
        this.tokens = value.user ? value : null;
      });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.headers.get('Authorization')) {
      if (this.tokens) {
        const date = Math.floor(Date.now() / 1000);
        if (this.tokens.expires_in <= date) {
          this.authService.refresh(true);
        }

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.tokens.access_token}`,
          },
        });
      } else if (this.clientCredentials) {
        request = request.clone({
          setHeaders: {
            Authorization: `Basic ${this.clientCredentials}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
