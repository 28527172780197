import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: 'loading.template.html',
})
export class LoadingComponent implements OnInit {
  @Input() loadingStatus: boolean;
  @Input() backdrop: boolean;
  @Input() backdropOpacity: string;
  @Input() animation: boolean = true;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      document.documentElement,
      `--loading-opacity`,
      this.backdropOpacity,
      2,
    );
    if (!this.animation)
      this.renderer.setStyle(
        document.documentElement,
        `--loading-animation`,
        'none',
        2,
      );
  }
}
