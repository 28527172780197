import { Injectable } from '@angular/core';
import { EvaluationQueryParamsInterface } from '@interfaces/evaluations/evaluation.queryParams.interface';
import { FileInterface } from '@interfaces/file/file.model.interface';
import { StudentsAssessments } from '@interfaces/students-assessments/students-assessments.model.interface';
import { StudentsAssessmentsQueryParamsInterface } from '@interfaces/students-assessments/students-assessments.queryParams.interface';
import { Students } from '@interfaces/students/students.model.interface';
import { StudentsQueryParamsInterface } from '@interfaces/students/students.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { EvaluationInterface } from './../../interfaces/evaluations/evaluation.model.interface';

@Injectable()
export class StudentsService extends AbstractService<
  Students,
  StudentsQueryParamsInterface
> {
  protected errorsSection = 'errors.students';
  protected endPoint = '/students/';
}

@Injectable()
export class StudentsAssesmentsService extends AbstractService<
  StudentsAssessments,
  StudentsAssessmentsQueryParamsInterface
> {
  protected errorsSection = 'errors.students';
  protected endPoint = '/studentsAssessments/';

  public findTableUsage(evaluationTableId: string, section: string) {
    const endPoint = `${this.endPoint}units-being-evaluated/`;
    this.getAll({ evaluationTableId }, section, [], endPoint);
  }

  public downloadAssesmentExcel(evaluationId: string, filename: string) {
    const endPoint = `/studentsAssessments/evaluation-resume-excel/?evaluation=${evaluationId}`;
    this.downloadExcel(endPoint, filename);
  }
}

@Injectable()
export class AssesmentsService extends AbstractService<
  EvaluationInterface,
  EvaluationQueryParamsInterface
> {
  protected errorsSection = 'errors.evaluations';
  protected endPoint = '/evaluations/';
}

@Injectable()
export class StudentsImportService extends AbstractService<
  FileInterface,
  StudentsQueryParamsInterface
> {
  protected errorsSection = 'errors.students';
  protected endPoint = '/students/import/';
}
