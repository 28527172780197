<div 
  *ngIf="loadingStatus"
  [class.loading]="backdrop"
  class="d-flex justify-content-center"
  [ngStyle]="{
    'opacity': backdropOpacity
  }"
>
  <div class="spinner__lg spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
