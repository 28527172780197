<div class="position-relative">
    
    <div (click)="bsModalRef.hide()" class="file-cdn-modal__close">
        <i class="fa fa-regular fa-xmark"></i>
    </div>
    
    <div class="file-cdn-modal">
        <app-loading [backdrop]="true" [loadingStatus]="(loadingSource$ | async)"></app-loading>
        <div class="file-cdn-modal__container">
            <!-- MODAL HEADER -->
            <div class="file-cdn-modal__header">
                <!-- TITLE -->
                <span class="file-cdn-modal__title">
                    <i class="fa fa-regular fa-files"></i>
                    Explorador del repositorio
                </span>
                
                <span>
                    {{ (headersSource$ | async)?.total || 0 }} archivos.
                </span>
            </div>
        
            <!-- SEARCH -->
            <div class="file-cdn-modal__tools">
        
                <div class="file-cdn-modal__search-container">
                    <input 
                        class="form__control form__control--border file-cdn-modal__search-input"
                        type="text" 
                        id="filename" 
                        placeholder="Buscar archivos..."
                        (input)="onSearch($event.target.value)"
                    />
                    <i class="fa-solid fa-magnifying-glass file-cdn-modal__search-input-icon"></i>
                </div>
        
                <div class="file-cdn-modal__filters-container">
                    <div
                        [class.file-cdn-modal__filters-type-container--selected]="fileType.selected"
                        class="file-cdn-modal__filters-type-container"
                        *ngFor="let fileType of fileTypes; index as i"
                        (click)="selectFilter(i)"
                    >
                        <span>
                            {{ fileType.label }}
                        </span>
                    </div>
                </div> 
                
            </div>
        
            <!-- FILES GRID -->
            <div *ngIf="!!(dataSource$ | async).length; else noData" class="file-cdn-modal__grid">
                <div 
                    class="file-cdn-modal__file"
                    *ngFor="let file of (dataSource$ | async); index as i"
                    (click)="fileSelected = file"
                    [class.file-cdn-modal__file--selected]="fileSelected?.id === file?.id"
                    [class.file-cdn-modal__file--disabled]="
                        allowOnlyExtensions.length &&
                        !allowOnlyExtensions.includes(file.name | getExtension)
                    "
                >
                    <span [outerHTML]="file.url | getFileThumbnail"></span>
                    <div class="file-cdn-modal__file-info">
                        <span class="file-cdn-modal__file-name">{{ file.name }}</span>
                        <div class="d-flex align-items-center gap-1">
                            <span class="file-cdn-modal__file-extension">{{ file.name | getExtension:false }}</span>
                            <span class="file-cdn-modal__file-size">{{ file.size | getFileSizeUnit }}</span>
                        </div>
                    </div>
                </div>
            </div>
        
            <!-- PAGINATOR -->
            <div class="file-cdn-modal__paginator-container">
                <ngb-pagination 
                    class="file-cdn-modal__paginator" 
                    (pageChange)="goToPage($event)" 
                    [collectionSize]="(headersSource$ | async)?.total ||0"
                    [page]="(headersSource$ | async)?.page || 1"
                    [maxSize]="10"
                    [ellipses]="false"
                ></ngb-pagination>
            </div>
            
            <!-- SELECTED FILE -->
            <div class="file-cdn-modal__file-selected">
                <ng-container *ngIf="fileSelected?.name">
                    Archivo seleccionado:
                    <span class="file-cdn-modal__file-selected-filename">{{ fileSelected?.name || 'Ningun archivo seleccionado' }}</span>
                    <i 
                        (click)="viewFile(fileSelected)"
                        class="fa-regular fa-eye file-cdn-modal__file-view"
                    ></i>
                </ng-container>
                
                <ng-container *ngIf="!fileSelected?.name">
                    Selecciona un archivo: 
                    <span>
                        {{ fileSelected?.name || 'Ningun archivo seleccionado' }}
                    </span>
                </ng-container>
            </div>
        
            
            <!-- SELECT FILE BUTTON -->
            <button
                class="button button__icon button--small"
                (click)="selectFile()"
                [disabled]="!fileSelected"
                [class.button--disabled]="!fileSelected"
                [class.button--primary]="fileSelected"
            >
                <i class="fa fa-regular fa-save"></i>
                Seleccionar
            </button>
        </div>
    </div>
</div>

<ng-template #noData>
    <span>No hay ningún archivo.</span>
</ng-template>
