import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Programs } from '@interfaces/programs/programs.model.interface';
import { UserRolesEnum } from '@interfaces/user/user.enum';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import {
  ProgramsMenuService,
  ProgramsService,
} from '@services/programs/programs.service';
import { UploadFilesComponent } from '@shared/components/upload-files/upload-files.component';
import {
  ASIDE_NAVIGATION_SECTION,
  HEADER_NAVIGATION_SECTION,
  MENU_SECTION,
  PROGRAM_SECTION,
} from '@shared/sections/sections';
import { ToastrService } from 'ngx-toastr';
import { filter, map, Subscription } from 'rxjs';

export const TEACHERS_BULK_SECTION = 'hasImportsTeachers';
export const ERROR_TEACHERS_BULK_SECTION =
  'errors.teachers.importTeachers.status';
export const IMPORT_TEACHERS_START_SECTION = 'importTeachers.start';

@Component({
  selector: 'app-bulk',
  templateUrl: './bulk.component.html',
  styleUrls: ['./bulk.component.scss'],
})
export class BulkComponent implements OnInit, AfterViewChecked {
  @ViewChild('importFile', { static: false })
  importFile: UploadFilesComponent;

  //Subscription
  private subMenu: Subscription;
  private subProgram: Subscription;
  private subImport: Subscription;
  private subImportErrors: Subscription;
  private subImportStatus: Subscription;

  //Data model
  private basicNavigation: BasicObservable;
  public program: Programs;

  //Booleans
  public isLoading: boolean;
  public isImporting: boolean;
  public startImport: boolean;

  //Variables
  public url: string;

  //enums
  public UserRolesEnum = UserRolesEnum;

  constructor(
    private basicObservableService: BasicObservableService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private programsService: ProgramsService,
    private menuService: ProgramsMenuService,
    private toastr: ToastrService,
    private errorsService: ErrorsService,
  ) {}

  ngOnInit() {
    this.isImporting = false;
    this.isLoading = true;
    this.errorsService.cleanSection(ERROR_TEACHERS_BULK_SECTION);

    this.updateNavigationMenu();

    this.subMenu = this.menuService
      .getObservable(MENU_SECTION)
      .subscribe((data) => this.updateSideNavigationMenu(data));

    this.subProgram = this.programsService
      .getObservable(PROGRAM_SECTION)
      .pipe(
        filter((programs) => !!programs?.length),
        map((programs) => programs[programs?.length - 1]),
      )
      .subscribe((program) => this.onProgram(program));

    this.subImportStatus = this.basicObservableService
      .getObservable(IMPORT_TEACHERS_START_SECTION)
      .pipe(
        filter((imports) => !!imports),
        map((imports) => imports[imports?.length - 1]),
      )
      .subscribe((response) => this.checkTeachersImport(response));

    this.subImportErrors = this.errorsService
      .getObservable(ERROR_TEACHERS_BULK_SECTION)
      .subscribe((response) => this.onError(response));
  }

  ngAfterViewChecked() {
    this.importFile.uploader.options.url = `${environment.api}/school-invitations/import-teachers`;
    this.importFile.uploader.options.autoUpload = false;
  }

  private updateNavigationMenu() {
    this.basicNavigation = {
      hash: HEADER_NAVIGATION_SECTION,
      payload: {
        type: UserRolesEnum.TEACHER,
        fixed: true,
        background: true,
        home: false,
        progressBar: true,
        showLevel: false,
      },
    };
    this.basicObservableService.update(
      this.basicNavigation,
      HEADER_NAVIGATION_SECTION,
    );
  }

  private updateSideNavigationMenu(data) {
    this.basicNavigation = {
      hash: ASIDE_NAVIGATION_SECTION,
      payload: {
        show: false,
        showPartial: false,
        menuData: data,
      },
    };
    this.basicObservableService.update(
      this.basicNavigation,
      ASIDE_NAVIGATION_SECTION,
    );
  }

  //Data setting
  private onProgram(data) {
    this.program = data;
  }

  //Import
  private checkTeachersImport(response) {
    if (this.isImporting && response?.hash === 'teachers-imports') {
      this.isImporting = !this.isImporting;
      this.importFile.delete(this.importFile.gallery[0]);
      console.log('Comenzando importacion en el servidor >>>>>>');
      this.toastr.success('Comenzando importación.');
      this.importFile.hideInput = false;
      this.startImport = false;
    }
  }

  private onError(errors: ErrorServiceInterface[]) {
    if (!errors || errors?.length < 1) return;
    const error = errors[errors.length - 1].payload.error;
    const id = errors[errors.length - 1].id;
    if (error.statusCode === 404) {
      this.errorsService.remove(id);
      this.isLoading = false;
      this.isImporting = false;
      this.startImport = false;
      this.importFile.hideInput = false;
      this.importFile.gallery = [];
      this.errorsService.cleanSection(ERROR_TEACHERS_BULK_SECTION);
    }
  }

  public import() {
    this.isImporting = !this.isImporting;
    this.importFile.uploader.uploadItem(this.importFile.uploader.queue[0]);
    this.importFile.uploader.onSuccessItem = (
      item,
      response,
      status,
      headers,
    ) => {
      const data = JSON.parse(response)?.data;
      this.basicObservableService.update(
        { hash: 'teachers-imports', payload: data },
        IMPORT_TEACHERS_START_SECTION,
      );
    };
    this.importFile.uploader.onErrorItem = (
      item,
      response,
      status,
      headers,
    ) => {
      const error = JSON.parse(response);
      switch (status) {
        case 409:
          if (error.errors?.length) {
            this.isLoading = false;
            this.isImporting = !this.isImporting;
            if (error.errors[0].values[0].includes('headers')) {
              this.toastr.error(
                'Error en la subida. El archivo no se corresponde con el modelo.',
              );
              this.importFile.delete(this.importFile.gallery[0]);
              this.importFile.fileError =
                'Ha ocurrido un error en la carga. El archivo no se corresponde con el modelo.';
            }
            if (error.errors[0].values[0].includes('email')) {
              console.log(
                'Importación realizada. Uno de los email no tiene el formato correcto. El profesor correspondiente no se importará. >>>>>>',
              );
              this.toastr.warning(
                'Error durante la subida. Uno de los email no tiene el formato correcto. El profesor correspondiente no se importará.',
              );
              this.importFile.delete(this.importFile.gallery[0]);
              this.importFile.fileError =
                'Aviso. Se ha relizado la carga. Uno de los email no tiene el formato correcto. El profesor correspondiente no se importará.';
            }
            if (error.errors[0].values[0].includes('empty')) {
              console.log(
                'Importación finalizada. Existe una colimna vacía. La columna no se importará.>>>>>>',
              );
              this.toastr.warning(
                'Error en la subida. Existe una columna sin datos. La columna no se importará.',
              );
              this.importFile.delete(this.importFile.gallery[0]);
              this.importFile.fileError =
                'Carga finalizada. Existe una columna sin datos. La columna no se importará.';
            }
          }
      }
    };
  }

  public onNavigateReturn() {
    this.router.navigate([`/admin/teachers/`]);
  }
}
