import { Injectable } from '@angular/core';
import { FilveInventoryInterface } from '@interfaces/file-inventory/file-inventory.interface';
import { FileInventoryQueryParamsInterface } from '@interfaces/file-inventory/file-inventory.params.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class FileInventoryService extends AbstractService<
  FilveInventoryInterface,
  FileInventoryQueryParamsInterface
> {
  protected errorsSection = 'errors.file-inventory';
  protected endPoint = '/files-inventory/';
}
