export const HEADER_NAVIGATION_SECTION = 'header-navigation-menu';
export const ASIDE_NAVIGATION_SECTION = 'aside-navigation-menu';
export const REGISTER_SECTION = 'register';
export const USER_SECTION = 'user';
export const TEACHER_SECTION = 'teacher';
export const SCHOOL_SECTION = 'school';
export const PROGRAM_SECTION = 'program';
export const PROGRAM_PROGRESS_SECTION = 'progress-program';
export const MENU_SECTION = 'menu';
export const ACTUAL_LEVEL = 'actual-level';
export const AGREEMENTS_SECTION = 'agreements';
export const ACTUAL_LEVEL_NUMBERS_SECTION = 'actual-level-numbers-in-use';
export const ACTUAL_LEVEL_NUMBERS_SECTION_UNIT =
  'actual-level-numbers-in-use-unit';
export const STUDENT_SECTION = 'student';
