import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import {
  AssesmentsService,
  StudentsAssesmentsService,
  StudentsImportService,
  StudentsService,
} from './students.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class StudentsServiceModule {
  static forRoot(): ModuleWithProviders<StudentsServiceModule> {
    return {
      ngModule: StudentsServiceModule,
      providers: [
        StudentsService,
        StudentsAssesmentsService,
        AssesmentsService,
        StudentsImportService,
      ],
    };
  }
}
