import { Injectable } from '@angular/core';
import { ActivityCategorySubprocessModelInterface } from '@interfaces/activity-categories-subprocess/activity-category-subprocess.model.interface';
import { ActivityCategorySubprocessQueryParamsInterface } from '@interfaces/activity-categories-subprocess/activity-category-subprocess.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class SubprocessesService extends AbstractService<
  ActivityCategorySubprocessModelInterface,
  ActivityCategorySubprocessQueryParamsInterface
> {
  protected errorsSection = 'errors.subprocesses';
  protected endPoint = '/activities-categories-subprocesses/';
}
