import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { StripePaymentService } from './stripe-payment.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class StripePaymentServiceModule {
  static forRoot(): ModuleWithProviders<StripePaymentServiceModule> {
    return {
      ngModule: StripePaymentServiceModule,
      providers: [StripePaymentService],
    };
  }
}
