import { activityTypeNamePipe } from './activities-name.pipe';
import { competenciesTypeNamePipe } from './competencies-name.pipe';
import { detailsSessionPipe } from './get-details-session.pipe';
import { getCompetencyFromDictionaryPipe } from './get-competency.pipe';
import { GetExtensionPipe } from './get-extension.pipe';
import { GetFileSizeUnit } from './file-size-to-units.pipe';
import { GetFileThumbnail } from './get-file-thumbnail.pipe';
import { NgModule } from '@angular/core';
import { SafeHtmlContentPipe, SafeHtmlPipe } from './safe-html.pipe';
import { TranslateDifficulty } from './difficulty.pipe';

@NgModule({
  imports: [],
  declarations: [
    activityTypeNamePipe,
    competenciesTypeNamePipe,
    detailsSessionPipe,
    getCompetencyFromDictionaryPipe,
    GetExtensionPipe,
    GetFileSizeUnit,
    GetFileThumbnail,
    SafeHtmlPipe,
    SafeHtmlContentPipe,
    TranslateDifficulty,
  ],
  exports: [
    activityTypeNamePipe,
    competenciesTypeNamePipe,
    detailsSessionPipe,
    getCompetencyFromDictionaryPipe,
    GetExtensionPipe,
    GetFileSizeUnit,
    GetFileThumbnail,
    SafeHtmlPipe,
    TranslateDifficulty,
    SafeHtmlContentPipe,
  ],
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
