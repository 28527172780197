import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ExtendedRecordset } from '@interfaces/global/extendedRecordset.interface';
import { ModelInterface } from '@interfaces/global/model.interface';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { vsprintf } from 'sprintf-js';
import { ErrorsService } from '../errors/errors.service';

export interface IError {
  hash?: string;
  payload?: any;
  section?: string;
}

export interface IHeaders {
  limit?: number;
  page?: number;
  total?: number;
}

interface IDataStore<T> {
  [key: string]: T;
}

interface IBehaviorSubjects<T> {
  [key: string]: BehaviorSubject<T>;
}

interface IObservable<T> {
  [key: string]: Observable<T>;
}

@Injectable()
export abstract class AbstractService<
  IModel extends ModelInterface,
  IOptions extends QueryParamsInterface,
> {
  protected options: IOptions;
  protected errorsSection: string;
  protected baseUrl = environment.api;
  protected endPoint: string;

  protected dataStore: IDataStore<IModel[]> = {};

  protected dataStoreHeaders: IDataStore<IHeaders> = {};

  protected behaviorSubjects: IBehaviorSubjects<IModel[]> = {};

  protected behaviorSubjectsHeaders: IBehaviorSubjects<IHeaders> = {};

  protected behaviorSubjectsUpdating: IBehaviorSubjects<boolean> = {};

  protected observable: IObservable<IModel[]> = {};

  protected observableHeaders: IObservable<IHeaders> = {};

  protected observableUpdating: IObservable<boolean> = {};

  protected static parseResponse(response) {
    return {
      body: response.body.data,
      headers: {
        total: +response.headers.get('count'),
        page: +response.headers.get('page'),
        limit: +response.headers.get('limit'),
      },
    };
  }

  protected static setParams(params) {
    let httpParams = new HttpParams();

    Object.keys(params).map((value) => {
      httpParams = httpParams.set(value, params[value]);
    });

    return httpParams;
  }

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
  ) {
    this.checkSection();
  }

  protected checkSection(section = 'standard', forze = false) {
    if (forze || !this.dataStore[section]) {
      this.dataStore[section] = [] as IModel[];
      this.dataStoreHeaders[section] = {} as IHeaders;

      this.behaviorSubjects[section] = new BehaviorSubject(
        this.dataStore[section],
      ) as BehaviorSubject<IModel[]>;
      this.behaviorSubjectsHeaders[section] = new BehaviorSubject(
        this.dataStoreHeaders[section],
      ) as BehaviorSubject<IHeaders>;
      this.behaviorSubjectsUpdating[section] = new BehaviorSubject(false);

      this.observable[section] = this.behaviorSubjects[section].asObservable();
      this.observableHeaders[section] =
        this.behaviorSubjectsHeaders[section].asObservable();
      this.observableUpdating[section] =
        this.behaviorSubjectsUpdating[section].asObservable();
    }
    return true;
  }

  public cleanSection(section = 'standard') {
    this.checkSection(section);

    this.dataStoreHeaders[section] = {} as IHeaders;
    this.dataStore[section] = [] as IModel[];
    this.next(section);
    this.endUpdating(section);
  }

  public getObservable(section = 'standard'): Observable<IModel[]> {
    this.checkSection(section);
    return this.observable[section];
  }

  public getObservableHeaders(section = 'standard'): Observable<IHeaders> {
    this.checkSection(section);
    return this.observableHeaders[section];
  }

  public getObservableUpdating(section = 'standard'): Observable<boolean> {
    this.checkSection(section);
    return this.observableUpdating[section];
  }

  protected next(section): void {
    this.behaviorSubjectsHeaders[section].next(
      Object.assign({}, this.dataStoreHeaders)[section],
    );

    this.behaviorSubjects[section].next(
      Object.assign({}, this.dataStore)[section],
    );
  }

  protected changeUpdatingStatus(
    section: string = null,
    param: boolean = false,
  ): void {
    if (section) {
      this.behaviorSubjectsUpdating[section].next(param);
    } else {
      Object.keys(this.dataStore).forEach((subSection) => {
        this.changeUpdatingStatus(subSection, param);
      });
    }
  }

  protected startUpdating(section: string = null): void {
    this.changeUpdatingStatus(section, true);
  }

  protected endUpdating(section: string = null): void {
    this.changeUpdatingStatus(section, false);
  }

  protected queryPost(
    model: any,
    section: string | string[] = 'standard',
    endpointUrl: string = null,
    addToEnd: boolean = true,
  ) {
    const sections = typeof section === 'string' ? [section] : section;
    sections.forEach((sec) => {
      this.checkSection(sec);
      this.startUpdating(sec);
    });

    this.http.post(endpointUrl, model).subscribe(
      (resp: ExtendedRecordset<IModel>) => {
        const data = resp.data;
        sections.forEach((sec) => {
          if (addToEnd) {
            this.dataStore[sec].push(data as IModel);
          } else {
            this.dataStore[sec].unshift(data as IModel);
          }
          this.next(sec);
          this.endUpdating(sec);
        });
      },
      (error) => {
        this.errorsService.create(`${this.errorsSection}.${section}`, {
          payload: error,
        } as IError);
        sections.forEach((sec) => {
          this.endUpdating(sec);
        });
      },
    );
  }

  protected queryPatch(
    model: any,
    addToSection = 'standard',
    endpointUrl: string = null,
  ) {
    let added = false;
    this.startUpdating();
    this.http.patch(endpointUrl, model).subscribe(
      (resp: ExtendedRecordset<IModel>) => {
        const data = resp.data;
        // Walk around all sections
        Object.keys(this.dataStore).forEach((section) => {
          // Walk around all objects inside the section
          this.dataStore[section].forEach((t, i) => {
            if (t.id === data.id) {
              if (addToSection === section) {
                added = true;
              }
              this.dataStore[section][i] = data as IModel;
              this.next(section);
            }
          });
        });

        // If it's not added, add it.
        if (!added && addToSection !== null) {
          this.checkSection(addToSection);
          this.dataStore[addToSection].push(data as IModel);
          this.next(addToSection);
        }

        this.endUpdating();
      },
      (error) => {
        this.errorsService.create(`${this.errorsSection}.${addToSection}`, {
          payload: error,
        } as IError);
        this.endUpdating();
      },
    );
  }

  protected queryGetAll(
    externalOptions: IOptions = {} as IOptions,
    section = 'standard',
    endpointUrl: string = null,
    replace: boolean = true,
    concat: boolean = true,
  ) {
    const options = Object.assign({}, this.options, externalOptions);
    const params = AbstractService.setParams(options);
    this.checkSection(section);
    this.startUpdating(section);
    this.http
      .get(endpointUrl, { observe: 'response', params })
      .pipe(map((resp) => AbstractService.parseResponse(resp)))
      .subscribe(
        (resp) => {
          if (replace) {
            this.dataStore[section] = resp.body;
          } else if (concat) {
            this.dataStore[section] = this.dataStore[section].concat(resp.body);
          } else {
            this.dataStore[section].unshift(resp.body);
          }
          this.dataStoreHeaders[section] = resp.headers;
          this.next(section);
          this.endUpdating(section);
        },
        (error) => {
          this.errorsService.create(`${this.errorsSection}.${section}`, {
            payload: error,
          } as IError);
          this.endUpdating(section);
        },
      );
  }

  protected queryGetOne(section = 'standard', endpointUrl: string = null) {
    this.checkSection(section);
    this.startUpdating(section);
    this.http.get(endpointUrl).subscribe(
      (resp: ExtendedRecordset<IModel>) => {
        const data = resp.data;
        let found = false;

        this.dataStore[section].forEach((item, index) => {
          if (item.id === data.id) {
            this.dataStore[section][index] = data as IModel;
            found = true;
          }
        });

        if (!found) {
          this.dataStore[section].push(data as IModel);
        }

        this.next(section);
        this.endUpdating(section);
      },
      (error) => {
        this.errorsService.create(`${this.errorsSection}.${section}`, {
          payload: error,
        } as IError);
        this.endUpdating(section);
      },
    );
  }

  protected queryDelete(id: string, endpointUrl: string = null) {
    this.startUpdating();
    this.http.delete(endpointUrl).subscribe(
      (response) => {
        // Walk around all sections
        Object.keys(this.dataStore).forEach((section) => {
          // Walk around all objects inside the section
          this.dataStore[section].forEach((t, i) => {
            if (t.id === id) {
              this.dataStore[section].splice(i, 1);
              this.next(section);
            }
          });
        });
        this.endUpdating();
      },
      (error) => {
        this.errorsService.create(`${this.errorsSection}`, {
          payload: error,
        } as IError);
        this.endUpdating();
      },
    );
  }

  protected downloadExcel(url: string, filename: string) {
    const endpointUrl = `${this.baseUrl}${url}`;

    this.http
      .get(endpointUrl, { responseType: 'blob' as 'json' })
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType }),
        );
        if (filename) downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  public create(
    object: IModel,
    section: string | string[] = 'standard',
    urlParams: string[] = [],
    url: string = null,
    addToEnd: boolean = true,
  ) {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}`;

    this.queryPost(object, section, endpointUrl, addToEnd);
  }

  public getAll(
    externalOptions: IOptions = {} as IOptions,
    section = 'standard',
    urlParams: string[] = [],
    url: string = null,
    replace: boolean = true,
    concat: boolean = true,
  ) {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}`;

    this.queryGetAll(externalOptions, section, endpointUrl, replace, concat);
  }

  public getOne(
    id: string,
    section = 'standard',
    urlParams: string[] = [],
    url: string = null,
  ) {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}${id}${urlParams}`;

    this.queryGetOne(section, endpointUrl);
  }

  public update(
    model: IModel,
    addToSection: string = null,
    urlParams: string[] = [],
    url: string = null,
  ) {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}${model.id}`;

    this.queryPatch(model, addToSection, endpointUrl);
  }

  public delete(id: string, urlParams: string[] = [], url: string = null) {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}${id}`;
    this.queryDelete(id, endpointUrl);
  }
}
