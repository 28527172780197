import { Injectable } from '@angular/core';
import { ThemeInterface } from '@interfaces/theme/theme.model.interface';
import { ThemeQueryParamsInterface } from '@interfaces/theme/theme.queryParams.interface';

import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ThemesService extends AbstractService<
  ThemeInterface,
  ThemeQueryParamsInterface
> {
  protected errorsSection = 'errors.themes';
  protected endPoint = '/themes/';
}
