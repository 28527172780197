import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'v2',
    loadChildren: () =>
      import('./modules/v2/v2.module').then((m) => m.V2Module),
  },
  {
    path: 'uikit',
    loadChildren: () =>
      import('./modules/uikit/uikit.module').then((m) => m.UIKitModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/v2/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'error/:error',
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then(
        (m) => m.ErrorPageModule,
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then(
        (m) => m.ErrorPageModule,
      ),
  },
];
