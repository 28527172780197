import { Injectable } from '@angular/core';
import { FilveInventoryInterface } from '@interfaces/file-inventory/file-inventory.interface';
import { FileInterface } from '@interfaces/file/file.model.interface';
import { Headers } from '@interfaces/global/headers.interface';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { FileInventoryService } from '@services/file-inventory/file-inventory.service';
import { FilesService } from '@services/load-content/load-content.service';
import { getAllData } from '@shared/utils/helpers/basic-observable.helper';
import { Observable, filter, map } from 'rxjs';

const defaultHeaders: Headers = {
  limit: 10,
  page: 1,
  total: 100,
  sort: '-updatedAt',
};

@Injectable({
  providedIn: 'root',
})
export class FilesFacade {
  section = 'files';
  searchSection = 'file-inventory';

  constructor(
    private filesService: FilesService,
    private fileInventoryService: FileInventoryService,
    private errorsService: ErrorsService,
  ) {}

  public isLoading$(): Observable<boolean> {
    return this.filesService.getObservableUpdating(this.section);
  }

  public files$(): Observable<FilveInventoryInterface[]> {
    return this.filesService
      .getObservable(this.section)
      .pipe(map(this.normalizeFiles));
  }

  public errors$(): Observable<ErrorServiceInterface[]> {
    return this.errorsService.getObservable(this.section).pipe(getAllData());
  }

  public headers$(): Observable<Headers> {
    return this.filesService
      .getObservableHeaders(this.section)
      .pipe(filter((t) => !!Object.keys(t).length));
  }

  public inventory$(): Observable<FilveInventoryInterface[]> {
    return this.fileInventoryService.getObservable(this.searchSection);
  }

  public inventoryHeaders$(): Observable<Headers> {
    return this.fileInventoryService
      .getObservableHeaders(this.searchSection)
      .pipe(filter((t) => !!Object.keys(t).length));
  }

  public isInventoryLoading$(): Observable<boolean> {
    return this.fileInventoryService.getObservableUpdating(this.searchSection);
  }

  private normalizeFiles(files: FileInterface[]): FilveInventoryInterface[] {
    return files.map((file) => ({
      id: file.id,
      name: file.filename,
      size: file.size,
      url: file.url,
      type: file.mimetype,
    }));
  }

  public listFiles(headers: Headers = defaultHeaders): void {
    delete headers.total;
    this.filesService.getAll(headers, this.section);
  }

  public searchFiles(
    names: string,
    headers: Headers = defaultHeaders,
    filters: string[] = [],
  ): void {
    delete headers.total;

    names = names === ',' && filters.length ? '' : names;
    names = !names && !filters.length ? ',' : names;

    const fileName = !filters.length
      ? names
      : filters.map((filter) => `${names}%%${filter}`).join(',');

    this.fileInventoryService.getAll(
      { fileName, ...headers },
      this.searchSection,
    );
  }

  public handlePage(
    pageHandler: {
      headers: Headers;
      searchMode: boolean;
      filters: string[];
      searchTerm: string;
    },
    page: number,
  ): void {
    const { headers, searchMode, filters, searchTerm } = pageHandler;
    const _headers = {
      ...defaultHeaders,
      ...headers,
      page,
    };

    this.searchFiles(searchTerm, _headers, filters);
    return;

    /*     if (searchMode) {
      this.searchFiles(searchTerm, _headers, filters);
      return;
    }

    this.listFiles(_headers); */
  }
}
