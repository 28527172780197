import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';

// services
import { ActivityTypesService } from './activity-types.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ActivityTypesServiceModule {
  static forRoot(): ModuleWithProviders<ActivityTypesServiceModule> {
    return {
      ngModule: ActivityTypesServiceModule,
      providers: [ActivityTypesService],
    };
  }
}
