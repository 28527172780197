import { ProgramsQueryParamsInterface } from '@interfaces/programs/programs.queryParams.interface';
import { Injectable } from '@angular/core';
import {
  ProgramMenuInterface,
  Programs,
} from '@interfaces/programs/programs.model.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService extends AbstractService<
  Programs,
  ProgramsQueryParamsInterface
> {
  protected errorsSection = 'errors.programs';
  protected endPoint = '/programs/';
}

@Injectable({
  providedIn: 'root',
})
export class ProgramsMenuService extends AbstractService<
  ProgramMenuInterface,
  ProgramsQueryParamsInterface
> {
  protected errorsSection = 'errors.programs';
  protected endPoint = '/programs/menu/';
}
