import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { UUID } from 'angular2-uuid';

export interface ErrorServiceInterface {
  id?: string;
  payload?: any;
  section?: string;
  statusCode?: number;
}

/****** Sections:
 * general
 *
 *
 */

@Injectable()
export class ErrorsService {
  private dataStore: {
    standard: ErrorServiceInterface[];
  };
  private behaviorSubjects: {
    standard: BehaviorSubject<ErrorServiceInterface[]>;
  };
  private observable: {
    standard: Observable<ErrorServiceInterface[]>;
  };

  constructor() {
    this.dataStore = {
      standard: [] as ErrorServiceInterface[],
    };
    this.behaviorSubjects = {
      standard: new BehaviorSubject(null) as BehaviorSubject<
        ErrorServiceInterface[]
      >,
    };
    this.observable = {
      standard: this.behaviorSubjects.standard.asObservable(),
    };
  }

  private checkSection(section = 'standard', forze = false) {
    if (forze || !this.dataStore[section]) {
      this.dataStore[section] = [] as ErrorServiceInterface[];
      this.behaviorSubjects[section] = new BehaviorSubject(
        null,
      ) as BehaviorSubject<ErrorServiceInterface[]>;
      this.observable[section] = this.behaviorSubjects[section].asObservable();

      const subSections = section.replace(/\.([^\.])*$/gi, '');
      if (subSections !== section) {
        this.checkSection(subSections);
      }
    }
    return true;
  }

  private checkSubsections(section): string {
    if (this.dataStore[section]) {
      return section;
    }

    // if exists a subsection
    const subSections = section.replace(/\.([^\.])*$/gi, '');
    if (subSections !== section) {
      return this.checkSubsections(subSections);
    }

    return 'standard';
  }

  getObservable(section = 'standard'): Observable<ErrorServiceInterface[]> {
    this.checkSection(section);
    return this.observable[section];
  }

  cleanSection(section = 'standard') {
    this.checkSection(section);
    this.dataStore[section] = [] as ErrorServiceInterface[];
    this.behaviorSubjects[section].next(
      Object.assign({}, this.dataStore)[section],
    );
  }

  create(
    section = 'standard',
    error: ErrorServiceInterface,
    realSection: string = null,
  ) {
    realSection = realSection || section;
    error.id = error.id || UUID.UUID();

    error.section = realSection;

    section = this.checkSubsections(section);

    if (!this.dataStore[section]) {
      section = 'standard';
    }

    this.dataStore[section].push(error);
    this.behaviorSubjects[section].next(
      Object.assign({}, this.dataStore)[section],
    );

    const subSections = section.replace(/\.([^\.])*$/gi, '');
    if (subSections !== section) {
      this.create(subSections, error, realSection);
    }
  }

  update(error: Error, key = 'id') {
    Object.keys(this.dataStore).forEach((section) => {
      this.dataStore[section].forEach((t, i) => {
        if (t[key] === error[key]) {
          this.dataStore[section][i] = error;
        }
      });

      this.behaviorSubjects[section].next(
        Object.assign({}, this.dataStore)[section],
      );
    });
  }

  remove(id: string, key = 'id') {
    Object.keys(this.dataStore).forEach((section) => {
      this.dataStore[section].forEach((t, i) => {
        if (t[key] === id) {
          this.dataStore[section].splice(i, 1);
        }
      });

      this.behaviorSubjects[section].next(
        Object.assign({}, this.dataStore)[section],
      );
    });
  }
}
