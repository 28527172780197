import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { ProcessesService } from './processes.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ProcessesServiceModule {
  static forRoot(): ModuleWithProviders<ProcessesServiceModule> {
    return {
      ngModule: ProcessesServiceModule,
      providers: [ProcessesService],
    };
  }
}
