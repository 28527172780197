import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';

// services
import { CategoriesService } from './categories.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class CategoriesServiceModule {
  static forRoot(): ModuleWithProviders<CategoriesServiceModule> {
    return {
      ngModule: CategoriesServiceModule,
      providers: [CategoriesService],
    };
  }
}
